<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512.003 512.003"
    xml:space="preserve"
  >
    <path
      d="M113.65 398.352c-3.91-3.9-10.239-3.9-14.139 0-3.91 3.91-3.91 10.239 0 14.139 3.9 3.91 10.229 3.91 14.139 0 3.9-3.9 3.9-10.229 0-14.139zM137.29 151.425l-.002-.001c.019.019.047.047.002.001z"
    />
    <path
      d="M383.28 284.292c-12.13-12.144-31.076-11.376-42.436-.004l-7.068 7.068-21.212-21.212 55.486-55.486c39.144 12.697 82.351 2.492 111.717-26.872 30.928-30.917 40.601-77.22 24.641-117.963a10 10 0 0 0-16.382-3.423l-60.268 60.268c-19.054-8.045-34.384-23.378-42.417-42.424l60.261-60.27a10 10 0 0 0-3.422-16.381C401.451-8.37 355.147 1.3 324.216 32.231c-29.355 29.356-39.561 72.571-26.871 111.726l-55.487 55.479-74.11-74.109-9.325-49.296a10 10 0 0 0-4.585-6.658L43.535 1.498a10.002 10.002 0 0 0-12.312 1.445L2.945 31.222A9.999 9.999 0 0 0 1.5 43.533l67.876 110.304a10 10 0 0 0 6.657 4.585l49.296 9.326 74.105 74.113L14.667 426.637c-19.544 19.53-19.559 51.156-.005 70.698 19.535 19.548 51.16 19.556 70.701.002l184.781-184.771 21.213 21.211-7.063 7.064c-11.732 11.716-11.745 30.708-.005 42.435l108.207 108.218c27.365 27.341 71.613 27.353 98.994-.004 27.282-27.292 27.282-71.702-.002-98.995L383.28 284.292zm-67.323-130.665a10 10 0 0 0 2.241-10.718c-13.06-33.337-5.147-71.23 20.159-96.536 21.495-21.497 52.062-30.443 81.211-24.647L366.642 74.66a9.998 9.998 0 0 0-2.416 10.23c9.838 29.524 33.345 53.035 62.878 62.891a9.998 9.998 0 0 0 10.236-2.415l52.932-52.932c5.797 29.152-3.151 59.722-24.646 81.208-25.313 25.315-63.199 33.227-96.522 20.161a9.995 9.995 0 0 0-10.721 2.238l-59.961 59.96-42.425-42.424 59.96-59.95zM71.219 483.198c-11.719 11.726-30.682 11.736-42.417-.004-11.726-11.719-11.733-30.684.003-42.414l184.769-184.778 42.427 42.422L71.219 483.198zm66.068-331.775a10.001 10.001 0 0 0-5.209-2.753l-47.985-9.078-61.422-99.813L39.78 22.67l99.813 61.42 9.078 47.985a9.994 9.994 0 0 0 2.754 5.212l168.209 168.209-14.137 14.138a6901498.803 6901498.803 0 0 1-168.21-168.211zM477.35 477.342c-19.549 19.533-51.158 19.544-70.716.003L298.425 369.128a9.983 9.983 0 0 1 .005-14.143l56.56-56.561a9.986 9.986 0 0 1 14.144.005l108.212 108.202c19.489 19.496 19.489 51.219.004 70.711zM137.277 151.413l.01.01.001.001-.011-.011z"
    />
    <path
      d="M85.363 426.639c-3.904-3.905-10.233-3.905-14.142 0l-28.278 28.278c-3.905 3.905-3.905 10.236 0 14.141s10.234 3.906 14.142 0l28.278-28.278c3.904-3.904 3.904-10.236 0-14.141zM456.131 413.71l-79.926-79.935c-3.903-3.904-10.235-3.906-14.141 0-3.906 3.905-3.906 10.235-.001 14.141l79.926 79.935c3.904 3.905 10.236 3.906 14.141 0 3.906-3.904 3.906-10.235.001-14.141zM427.852 441.987l-79.935-79.925c-3.905-3.904-10.237-3.904-14.141.001a10 10 0 0 0 .001 14.142l79.935 79.925c3.905 3.905 10.236 3.904 14.141-.001 3.904-3.905 3.904-10.236-.001-14.142z"
    />
  </svg>
</template>
