<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.5158 5.37591L14.5227 0.156467C14.1857 0.0210053 13.8143 0.0210053 13.4772 0.156467L0.484259 5.37591C0.190094 5.49409 0 5.77529 0 6.09231V21.8596C0.000109375 22.1765 0.190258 22.4577 0.484259 22.5758L13.7119 27.8895C13.8039 27.9266 13.9008 27.9453 14 27.9453C14.0992 27.9453 14.1961 27.9265 14.2877 27.8896L27.5157 22.5758C27.8099 22.4576 27.9999 22.1765 27.9999 21.8595V6.09379C27.9999 6.0933 28 6.09281 28 6.09231C28.0001 5.77529 27.81 5.49409 27.5158 5.37591ZM13.8002 0.960374C13.929 0.908585 14.071 0.908585 14.1997 0.960374L26.9749 6.09231L22.4775 7.89897L9.50263 2.68681L13.8002 0.960374ZM8.34057 3.15357L21.3155 8.36573L19.4785 9.10369L6.50361 3.89152L8.34057 3.15357ZM22.0444 9.00662V11.5163L21.2315 11.2284C21.0702 11.1714 20.8903 11.2145 20.7724 11.3388L19.9118 12.2471V9.8633L22.0444 9.00662ZM13.5669 26.8976H13.5668L0.866361 21.7956V6.96218L13.4773 12.0281C13.5069 12.0399 13.5369 12.0495 13.5669 12.0592V26.8976ZM14.1998 11.2242C14.071 11.2759 13.929 11.2759 13.8003 11.2242L1.02512 6.09231L5.34156 4.35839L18.3165 9.57056L14.1998 11.2242ZM14.4332 26.8976V12.0592C14.4631 12.0495 14.4932 12.04 14.5227 12.0281L19.0453 10.2113V13.334C19.0453 13.5113 19.1534 13.6707 19.3182 13.7364C19.3702 13.7571 19.4245 13.7672 19.4784 13.7672C19.5951 13.7672 19.7095 13.72 19.793 13.6319L21.2076 12.139L22.3329 12.5375C22.4655 12.5845 22.6127 12.5641 22.7275 12.4829C22.8424 12.4017 22.9107 12.2699 22.9107 12.1292V8.65848L27.1336 6.96207V21.7957H27.1336L14.4332 26.8976Z"
    />
    <path
      d="M23.4724 16.7421L18.3783 18.7885C18.1563 18.8777 18.0486 19.13 18.1378 19.3519C18.2057 19.5209 18.3682 19.6237 18.5399 19.6237C18.5936 19.6237 18.6483 19.6136 18.7013 19.5924L23.7954 17.546C24.0174 17.4568 24.1251 17.2045 24.0359 16.9826C23.9467 16.7606 23.6946 16.6529 23.4724 16.7421Z"
    />
    <path
      d="M9.41149 12.2108L5.01789 10.4459C4.48124 10.2304 3.87497 10.295 3.39607 10.6188C2.91707 10.9426 2.6311 11.4811 2.6311 12.0592C2.6311 12.7732 3.0592 13.4064 3.72168 13.6726L8.11523 15.4375C8.32474 15.5217 8.54469 15.5631 8.76366 15.5631C9.10568 15.5631 9.44501 15.462 9.7371 15.2646C10.2161 14.9409 10.5021 14.4023 10.5021 13.8242C10.5021 13.1103 10.074 12.477 9.41149 12.2108ZM9.25186 14.5469C9.00784 14.7118 8.71122 14.7434 8.43816 14.6337L4.04461 12.8687C3.71217 12.7351 3.49736 12.4174 3.49736 12.0592C3.49736 11.7648 3.63725 11.5013 3.88121 11.3365C4.12517 11.1716 4.42174 11.1399 4.6949 11.2497L9.08851 13.0146C9.4209 13.1482 9.63571 13.4659 9.63571 13.8241C9.63571 14.1186 9.49582 14.382 9.25186 14.5469Z"
    />
    <path
      d="M25.5779 13.7168C25.3235 13.5448 25.0013 13.5105 24.7162 13.625L16.7688 16.8175C16.4168 16.959 16.1895 17.2954 16.1895 17.6748V21.7368C16.1895 22.044 16.3414 22.3301 16.5959 22.5021C16.7511 22.607 16.9314 22.6607 17.1131 22.6607C17.2294 22.6607 17.3464 22.6388 17.4577 22.594L25.405 19.4015C25.757 19.2602 25.9844 18.9237 25.9844 18.5443V14.4822C25.9844 14.175 25.8325 13.8889 25.5779 13.7168ZM25.1182 18.5443C25.1182 18.5679 25.104 18.5888 25.0822 18.5976L17.1347 21.7902C17.1278 21.793 17.1063 21.8015 17.0811 21.7844C17.0558 21.7673 17.0558 21.7444 17.0558 21.7368V17.6748H17.0558C17.0558 17.6511 17.07 17.6302 17.0919 17.6214L25.0392 14.4289C25.0429 14.4274 25.0508 14.4242 25.0611 14.4242C25.0701 14.4242 25.0811 14.4266 25.0929 14.4345C25.1182 14.4516 25.1182 14.4746 25.1182 14.4821V18.5443Z"
    />
  </svg>
</template>
