<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.299 8.6975V4.91941C27.299 3.73587 26.3361 2.77304 25.1526 2.77304H23.3126V1.77275C23.3125 0.795211 22.5173 0 21.5398 0H1.82592C1.20571 0 0.70105 0.504656 0.70105 1.12487V26.8751C0.70105 27.4953 1.20571 28 1.82592 28H21.5397C22.5172 28 23.3125 27.2048 23.3125 26.2273V25.227H25.1525C26.336 25.227 27.2989 24.2641 27.2989 23.0806V19.3025C27.2989 18.6071 26.9663 17.9883 26.452 17.5958C26.9662 17.2033 27.2989 16.5844 27.2989 15.889V12.1109C27.2989 11.4156 26.9663 10.7967 26.452 10.4042C26.9663 10.0118 27.299 9.39285 27.299 8.6975ZM3.32178 27.1206H1.82592C1.69057 27.1206 1.58042 27.0105 1.58042 26.8751V1.12487C1.58042 0.989516 1.69057 0.879375 1.82592 0.879375H3.32178V27.1206ZM22.4332 26.2272C22.4332 26.7198 22.0324 27.1206 21.5398 27.1206H4.20116V0.879375H21.5398C22.0325 0.879375 22.4332 1.28013 22.4332 1.77275V26.2272H22.4332ZM26.4196 23.0805C26.4196 23.7792 25.8512 24.3476 25.1525 24.3476H23.3125V18.0354H25.1525C25.8512 18.0354 26.4196 18.6039 26.4196 19.3025V23.0805ZM26.4196 15.889C26.4196 16.5877 25.8512 17.1561 25.1525 17.1561H23.3125V10.8439H25.1525C25.1527 10.8439 25.1527 10.8439 25.1528 10.8439C25.8514 10.844 26.4195 11.4124 26.4195 12.1109V15.889H26.4196ZM26.4196 8.6975C26.4196 9.39608 25.8513 9.96445 25.1529 9.96461C25.1528 9.96461 25.1527 9.96461 25.1526 9.96461H23.3126V3.65236H25.1526C25.8513 3.65236 26.4197 4.22078 26.4197 4.91941V8.6975H26.4196Z"
    />
    <path
      d="M19.0365 17.0797H7.59774C6.78421 17.0797 6.12244 17.7415 6.12244 18.555C6.12244 19.3685 6.78426 20.0303 7.59774 20.0303H19.0365C19.85 20.0303 20.5118 19.3685 20.5118 18.555C20.5118 17.7415 19.85 17.0797 19.0365 17.0797ZM19.0365 19.1509H7.59774C7.26907 19.1509 7.00176 18.8836 7.00176 18.555C7.00176 18.2263 7.26907 17.959 7.59774 17.959H19.0365C19.3651 17.959 19.6325 18.2263 19.6325 18.555C19.6325 18.8836 19.3651 19.1509 19.0365 19.1509Z"
    />
    <path
      d="M18.9938 5.8219H7.64051C6.80346 5.8219 6.12244 6.50287 6.12244 7.33997V13.9476C6.12244 14.7846 6.80341 15.4656 7.64051 15.4656H18.9939C19.8309 15.4656 20.5119 14.7846 20.5119 13.9476V7.33997C20.5118 6.50287 19.8308 5.8219 18.9938 5.8219ZM19.6325 13.9475H19.6325C19.6325 14.2997 19.3459 14.5862 18.9938 14.5862H7.64051C7.28837 14.5862 7.00181 14.2997 7.00181 13.9475V7.33997C7.00181 6.98784 7.28837 6.70127 7.64051 6.70127H18.9939C19.346 6.70127 19.6325 6.98778 19.6325 7.33997V13.9475Z"
    />
    <path
      d="M16.7877 11.3911H9.84655C9.60368 11.3911 9.40686 11.588 9.40686 11.8308C9.40686 12.0736 9.60368 12.2705 9.84655 12.2705H16.7876C17.0305 12.2705 17.2273 12.0736 17.2273 11.8308C17.2273 11.588 17.0305 11.3911 16.7877 11.3911Z"
    />
    <path
      d="M10.9609 9.92564H15.6733C15.9161 9.92564 16.1129 9.72876 16.1129 9.48595C16.1129 9.24314 15.9161 9.04626 15.6733 9.04626H10.9609C10.7181 9.04626 10.5212 9.24314 10.5212 9.48595C10.5212 9.72876 10.7181 9.92564 10.9609 9.92564Z"
    />
  </svg>
</template>
