<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 14.0001C28 13.6831 27.8099 13.4019 27.5157 13.2837L20.6818 10.5384L27.5157 7.79317C27.8099 7.67499 28 7.39384 28 7.07682C28 6.7598 27.8099 6.47859 27.5157 6.36041L14.2878 1.04665C14.1021 0.972108 13.8977 0.972054 13.7122 1.04665L0.484367 6.36047C0.190094 6.47865 0 6.7598 0 7.07687C0 7.3939 0.190148 7.6751 0.484258 7.79323L7.31812 10.5385L0.484258 13.2837C0.190094 13.4019 0 13.6831 0 14.0001C0 14.3171 0.190148 14.5983 0.484258 14.7164L7.31812 17.4617L0.484367 20.207C0.190148 20.3251 0 20.6063 0 20.9233C0 21.2403 0.190094 21.5215 0.484258 21.6397L13.712 26.9534C13.8049 26.9907 13.9024 27.0093 13.9999 27.0093C14.0975 27.0093 14.1949 26.9907 14.2877 26.9534L27.5157 21.6397C27.8099 21.5215 27.9999 21.2403 27.9999 20.9233C27.9999 20.6062 27.8098 20.3251 27.5157 20.2069L20.6818 17.4616L27.5156 14.7164C27.8099 14.5983 28 14.3171 28 14.0001ZM1.02512 7.07682L14 1.86472L26.9749 7.07687L14 12.289L1.02512 7.07682ZM26.9749 20.9234L13.9999 26.1355L1.02512 20.9234L8.48023 17.9286L13.7121 20.0303C13.8049 20.0676 13.9024 20.0862 14 20.0862C14.0975 20.0862 14.195 20.0676 14.2877 20.0303L19.5197 17.9286L26.9749 20.9234ZM13.9999 19.2122L1.02506 14.0001L8.48028 11.0053L13.7122 13.107C13.805 13.1443 13.9025 13.1629 13.9999 13.1629C14.0975 13.1629 14.1949 13.1443 14.2877 13.107L19.5197 11.0053L26.9749 14.0001L13.9999 19.2122Z"
    />
  </svg>
</template>
