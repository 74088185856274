
import { defineComponent } from "vue";
import MainNavigation from "@/components/MainNavigation.vue";

export default defineComponent({
  name: "App",
  components: {
    MainNavigation,
  },
});
