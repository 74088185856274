
import IconHowToUse from "@/assets/icons/IconHowToUse.vue";
import IconLayouts from "@/assets/icons/IconLayouts.vue";
import IconForms from "@/assets/icons/IconForms.vue";
import IconComponents from "@/assets/icons/IconComponents.vue";
import IconUtilities from "@/assets/icons/IconUtilities.vue";
import IconDevs from "@/assets/icons/IconDevs.vue";
import IconDesign from "@/assets/icons/IconDesign.vue";
import TransitionExpand from "@/components/common/TransitionExpand.vue";

import { HOW_TO_USE_ROUTE_NAME } from "@/router/index";

import {
  DESIGN_COLORS_ROUTE_NAME,
  DESIGN_TYPOGRAPHY_ROUTE_NAME,
} from "@/router/modules/design";

import {
  APP_LAYOUTS_ROUTE_NAME,
  FLEXBOX_GRID_ROUTE_NAME,
  LAYOUT_HELPERS_ROUTE_NAME,
  LAYOUT_OBJECTS_ROUTE_NAME,
  RESPONSIVE_BREAKPOINTS_ROUTE_NAME,
} from "@/router/modules/layouts";

import {
  CHECK_AND_RADIO_ROUTE_NAME,
  SELECT_AND_OPTION_ROUTE_NAME,
  INPUT_AND_VALIDATION_ROUTE_NAME,
  INPUT_WITH_ICON_ROUTE_NAME,
  FORM_GROUP_OBJECT_ROUTE_NAME,
} from "@/router/modules/forms";

import {
  HOW_TO_USE_UTILITIES_ROUTE_NAME,
  COLORS_UTILITY_CLASSES_ROUTE_NAME,
  TYPOGRAPHY_UTILITY_CLASSES_ROUTE_NAME,
  GAPS_UTILITY_CLASSES_ROUTE_NAME,
  LAYOUT_UTILITY_CLASSES_ROUTE_NAME,
} from "@/router/modules/utilities";

import {
  USER_HINT_COMPONENT_ROUTE_NAME,
  BUTTON_COMPONENT_ROUTE_NAME,
  BASIC_BOX_COMPONENT_ROUTE_NAME,
  PILL_BUTTON_COMPONENT_ROUTE_NAME,
  DATA_TABLE_COMPONENT_ROUTE_NAME,
  TABS_COMPONENT_ROUTE_NAME,
} from "@/router/modules/components";

import {
  CSS_RULES_ROUTE_NAME,
  HTML_RULES_ROUTE_NAME,
} from "@/router/modules/developers";

export default {
  name: "MainNavigation",
  components: {
    IconHowToUse,
    IconLayouts,
    IconForms,
    IconComponents,
    IconUtilities,
    TransitionExpand,
    IconDevs,
    IconDesign,
  },

  data(): {
    layoutsIsOpen: boolean;
    formsIsOpen: boolean;
    componentsIsOpen: boolean;
    utilitiesIsOpen: boolean;
    devsIsOpen: boolean;
    designIsOpen: boolean;
    hiddenSection: boolean;
    HOW_TO_USE_ROUTE_NAME: string;
    APP_LAYOUTS_ROUTE_NAME: string;
    FLEXBOX_GRID_ROUTE_NAME: string;
    LAYOUT_HELPERS_ROUTE_NAME: string;
    CSS_RULES_ROUTE_NAME: string;
    HTML_RULES_ROUTE_NAME: string;
    CHECK_AND_RADIO_ROUTE_NAME: string;
    SELECT_AND_OPTION_ROUTE_NAME: string;
    INPUT_AND_VALIDATION_ROUTE_NAME: string;
    LAYOUT_OBJECTS_ROUTE_NAME: string;
    INPUT_WITH_ICON_ROUTE_NAME: string;
    RESPONSIVE_BREAKPOINTS_ROUTE_NAME: string;
    FORM_GROUP_OBJECT_ROUTE_NAME: string;
    USER_HINT_COMPONENT_ROUTE_NAME: string;
    HOW_TO_USE_UTILITIES_ROUTE_NAME: string;
    COLORS_UTILITY_CLASSES_ROUTE_NAME: string;
    DESIGN_COLORS_ROUTE_NAME: string;
    DESIGN_TYPOGRAPHY_ROUTE_NAME: string;
    TYPOGRAPHY_UTILITY_CLASSES_ROUTE_NAME: string;
    GAPS_UTILITY_CLASSES_ROUTE_NAME: string;
    LAYOUT_UTILITY_CLASSES_ROUTE_NAME: string;
    BUTTON_COMPONENT_ROUTE_NAME: string;
    BASIC_BOX_COMPONENT_ROUTE_NAME: string;
    PILL_BUTTON_COMPONENT_ROUTE_NAME: string;
    DATA_TABLE_COMPONENT_ROUTE_NAME: string;
    TABS_COMPONENT_ROUTE_NAME: string;
  } {
    return {
      layoutsIsOpen: false,
      formsIsOpen: false,
      componentsIsOpen: false,
      utilitiesIsOpen: false,
      devsIsOpen: false,
      designIsOpen: false,
      hiddenSection: false,
      HOW_TO_USE_ROUTE_NAME,
      APP_LAYOUTS_ROUTE_NAME,
      FLEXBOX_GRID_ROUTE_NAME,
      LAYOUT_HELPERS_ROUTE_NAME,
      CSS_RULES_ROUTE_NAME,
      HTML_RULES_ROUTE_NAME,
      CHECK_AND_RADIO_ROUTE_NAME,
      SELECT_AND_OPTION_ROUTE_NAME,
      INPUT_AND_VALIDATION_ROUTE_NAME,
      LAYOUT_OBJECTS_ROUTE_NAME,
      INPUT_WITH_ICON_ROUTE_NAME,
      RESPONSIVE_BREAKPOINTS_ROUTE_NAME,
      FORM_GROUP_OBJECT_ROUTE_NAME,
      USER_HINT_COMPONENT_ROUTE_NAME,
      HOW_TO_USE_UTILITIES_ROUTE_NAME,
      COLORS_UTILITY_CLASSES_ROUTE_NAME,
      DESIGN_COLORS_ROUTE_NAME,
      DESIGN_TYPOGRAPHY_ROUTE_NAME,
      TYPOGRAPHY_UTILITY_CLASSES_ROUTE_NAME,
      GAPS_UTILITY_CLASSES_ROUTE_NAME,
      LAYOUT_UTILITY_CLASSES_ROUTE_NAME,
      BUTTON_COMPONENT_ROUTE_NAME,
      BASIC_BOX_COMPONENT_ROUTE_NAME,
      PILL_BUTTON_COMPONENT_ROUTE_NAME,
      DATA_TABLE_COMPONENT_ROUTE_NAME,
      TABS_COMPONENT_ROUTE_NAME,
    };
  },
};
