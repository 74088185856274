<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <path
      d="M243.207 83.436c-28.315 0-51.35 23.035-51.35 51.35 0 28.314 23.035 51.349 51.35 51.349 28.314 0 51.35-23.035 51.35-51.349 0-28.315-23.036-51.35-51.35-51.35zm0 86.708c-19.498 0-35.359-15.861-35.359-35.358 0-19.498 15.861-35.359 35.359-35.359 19.496 0 35.359 15.861 35.359 35.359 0 19.496-15.863 35.358-35.359 35.358z"
    />
    <path
      d="M408.697 47.726H103.302c-13.227 0-23.987 10.76-23.987 23.987v297.966c0 13.227 10.76 23.987 23.987 23.987h305.395c13.227 0 23.987-10.76 23.987-23.987V71.713c0-13.227-10.76-23.987-23.987-23.987zM103.302 377.674c-4.409 0-7.996-3.586-7.996-7.996v-7.297l42.05-72.835c15.12 8.953 32.249 13.662 50.045 13.662s34.925-4.709 50.046-13.662l8.676 15.026-42.205 73.101H103.302zm42.051-101.975 31.448-54.471c3.128-5.418 6.992-8.525 10.602-8.525s7.474 3.108 10.602 8.525l31.449 54.471c-12.695 7.549-27.09 11.518-42.052 11.518-14.96 0-29.356-3.969-42.049-11.518zm271.339 93.979c0 4.409-3.586 7.996-7.996 7.996H222.385l86.378-149.612c15.469 9.197 33.006 14.032 51.227 14.032 18.223 0 35.76-4.836 51.227-14.032l5.474 9.482v132.134zm-99.934-155.464 32.239-55.84c3.23-5.596 7.238-8.804 10.993-8.804 3.757 0 7.763 3.209 10.993 8.804l32.239 55.84c-13.041 7.793-27.846 11.888-43.233 11.888-15.385.001-30.19-4.095-43.231-11.888zm99.934-8.652-31.86-55.182c-6.164-10.677-15.218-16.799-24.842-16.799-9.623 0-18.678 6.124-24.842 16.799l-79.792 138.203-43.504-75.349c-6.062-10.499-14.973-16.521-24.451-16.521-9.477 0-18.39 6.022-24.452 16.521L95.307 330.399V71.713c0-4.409 3.586-7.996 7.996-7.996h305.395c4.409 0 7.996 3.586 7.996 7.996v133.849z"
    />
    <path
      d="M456.423 0H55.577C42.35 0 31.59 10.76 31.59 23.987v464.026C31.59 501.24 42.35 512 55.577 512h400.846c13.227 0 23.987-10.76 23.987-23.987V23.987C480.41 10.76 469.649 0 456.423 0zm7.996 488.013c0 4.409-3.586 7.996-7.996 7.996H55.577c-4.409 0-7.996-3.586-7.996-7.996V23.987c0-4.409 3.586-7.996 7.996-7.996h400.846c4.409 0 7.996 3.586 7.996 7.996v464.026z"
    />
  </svg>
</template>
