<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.375 15.6406C18.0731 15.6406 17.8281 15.8856 17.8281 16.1875C17.8281 16.4894 18.0731 16.7344 18.375 16.7344C18.6769 16.7344 18.9219 16.4894 18.9219 16.1875C18.9219 15.8856 18.6769 15.6406 18.375 15.6406Z"
    />
    <path
      d="M14 11.2656C13.6981 11.2656 13.4531 11.5106 13.4531 11.8125C13.4531 12.1144 13.6981 12.3594 14 12.3594C14.3019 12.3594 14.5469 12.1144 14.5469 11.8125C14.5469 11.5106 14.3019 11.2656 14 11.2656Z"
    />
    <path
      d="M14 20.0156C13.6981 20.0156 13.4531 20.2606 13.4531 20.5625C13.4531 20.8644 13.6981 21.1094 14 21.1094C14.3019 21.1094 14.5469 20.8644 14.5469 20.5625C14.5469 20.2606 14.3019 20.0156 14 20.0156Z"
    />
    <path
      d="M9.625 15.6406C9.32313 15.6406 9.07812 15.8856 9.07812 16.1875C9.07812 16.4894 9.32313 16.7344 9.625 16.7344C9.92687 16.7344 10.1719 16.4894 10.1719 16.1875C10.1719 15.8856 9.92687 15.6406 9.625 15.6406Z"
    />
    <path
      d="M18.2537 11.9337C18.0829 11.763 17.8203 11.7245 17.6077 11.839L12.5808 14.546C12.5806 14.5461 12.5805 14.5462 12.5803 14.5463C12.5787 14.5471 12.5774 14.5481 12.5758 14.549C12.4805 14.6017 12.4046 14.6828 12.3586 14.7682L12.356 14.7731C12.356 14.7732 12.3559 14.7733 12.3559 14.7734L9.65157 19.7951C9.53706 20.0077 9.57561 20.2703 9.74635 20.4411C9.91566 20.6104 10.1779 20.6514 10.3924 20.5359L15.4192 17.8289C15.5061 17.7819 15.5899 17.7022 15.6412 17.607C15.6413 17.6069 15.6414 17.6068 15.6414 17.6066L18.3485 12.5798C18.463 12.3671 18.4244 12.1046 18.2537 11.9337ZM11.479 18.7085L12.9731 15.934L14.2535 17.2144L11.479 18.7085ZM15.027 16.4409L13.7466 15.1605L16.5211 13.6664L15.027 16.4409Z"
    />
    <path
      d="M16.0315 4.5623C16.4682 4.07755 16.7344 3.43656 16.7344 2.73438C16.7344 1.22664 15.5077 0 14 0C12.4923 0 11.2656 1.22664 11.2656 2.73438C11.2656 3.43656 11.5318 4.07755 11.9685 4.5623C6.49387 5.57982 2.1875 10.5706 2.1875 16.1875C2.1875 22.528 7.65253 28 14 28C20.3403 28 25.8125 22.535 25.8125 16.1875C25.8125 10.5414 21.474 5.57386 16.0315 4.5623ZM14 1.09375C14.9046 1.09375 15.6406 1.82973 15.6406 2.73438C15.6406 3.63902 14.9046 4.375 14 4.375C13.0954 4.375 12.3594 3.63902 12.3594 2.73438C12.3594 1.82973 13.0954 1.09375 14 1.09375ZM14 26.9062C8.29002 26.9062 3.28125 21.8975 3.28125 16.1875C3.28125 10.4775 8.29002 5.46875 14 5.46875C19.71 5.46875 24.7188 10.4775 24.7188 16.1875C24.7188 21.8975 19.71 26.9062 14 26.9062Z"
    />
    <path
      d="M14 7.98438C9.4768 7.98438 5.79688 11.6643 5.79688 16.1875C5.79688 20.7107 9.4768 24.3906 14 24.3906C18.5232 24.3906 22.2031 20.7107 22.2031 16.1875C22.2031 11.6643 18.5232 7.98438 14 7.98438ZM14.5469 23.276V22.75C14.5469 22.448 14.302 22.2031 14 22.2031C13.698 22.2031 13.4531 22.448 13.4531 22.75V23.276C9.96805 23.0097 7.17779 20.2194 6.91146 16.7344H7.4375C7.73948 16.7344 7.98438 16.4895 7.98438 16.1875C7.98438 15.8855 7.73948 15.6406 7.4375 15.6406H6.91146C7.17779 12.1556 9.96805 9.36529 13.4531 9.09896V9.625C13.4531 9.92704 13.698 10.1719 14 10.1719C14.302 10.1719 14.5469 9.92704 14.5469 9.625V9.09896C18.0319 9.36534 20.8222 12.1556 21.0885 15.6406H20.5625C20.2605 15.6406 20.0156 15.8855 20.0156 16.1875C20.0156 16.4895 20.2605 16.7289 20.5625 16.7289H21.0885C20.8222 20.214 18.0319 23.0097 14.5469 23.276Z"
    />
  </svg>
</template>
