<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.89062 10.1719C6.58875 10.1719 6.34375 10.4169 6.34375 10.7188C6.34375 11.0206 6.58875 11.2656 6.89062 11.2656C7.1925 11.2656 7.4375 11.0206 7.4375 10.7188C7.4375 10.4169 7.1925 10.1719 6.89062 10.1719Z"
    />
    <path
      d="M22.2031 2.1875C21.9011 2.1875 21.6562 2.43234 21.6562 2.73438C21.6562 3.03641 21.9011 3.28125 22.2031 3.28125C23.1078 3.28125 23.8438 4.01723 23.8438 4.92188C23.8438 5.22391 24.0886 5.46875 24.3906 5.46875C24.6926 5.46875 24.9375 5.22391 24.9375 4.92188C24.9375 3.41414 23.7109 2.1875 22.2031 2.1875Z"
    />
    <path
      d="M22.2031 20.3438C21.9011 20.3438 21.6562 20.5886 21.6562 20.8906C21.6562 21.1927 21.9011 21.4375 22.2031 21.4375C23.1078 21.4375 23.8438 22.1735 23.8438 23.0781C23.8438 23.3802 24.0886 23.625 24.3906 23.625C24.6926 23.625 24.9375 23.3802 24.9375 23.0781C24.9375 21.5704 23.7109 20.3438 22.2031 20.3438Z"
    />
    <path
      d="M22.2031 18.1562C20.6327 18.1562 19.1605 18.9127 18.2392 20.1614L12.3561 16.5143C12.7169 15.7303 12.9062 14.871 12.9062 14C12.9062 13.129 12.7169 12.2697 12.3561 11.4856L18.2392 7.83858C19.1604 9.08731 20.6327 9.84375 22.2031 9.84375C24.917 9.84375 27.125 7.6358 27.125 4.92188C27.125 2.20795 24.917 0 22.2031 0C19.4892 0 17.2812 2.20795 17.2812 4.92188C17.2812 5.60153 17.4226 6.27293 17.692 6.8909L11.8098 10.5375C10.6899 8.95021 8.85303 7.98438 6.89062 7.98438C3.57361 7.98438 0.875 10.683 0.875 14C0.875 17.317 3.57361 20.0156 6.89062 20.0156C8.85314 20.0156 10.6901 19.0498 11.8098 17.4625L17.692 21.1091C17.4225 21.7271 17.2812 22.3985 17.2812 23.0781C17.2812 25.792 19.4892 28 22.2031 28C24.917 28 27.125 25.792 27.125 23.0781C27.125 20.3642 24.917 18.1562 22.2031 18.1562ZM22.2031 1.09375C24.314 1.09375 26.0312 2.81105 26.0312 4.92188C26.0312 7.0327 24.314 8.75 22.2031 8.75C20.8338 8.75 19.5599 8.0109 18.8786 6.82123C18.5492 6.24591 18.375 5.58917 18.375 4.92188C18.375 2.81105 20.0923 1.09375 22.2031 1.09375ZM11.1651 16.4419C10.2895 17.9716 8.65156 18.9219 6.89062 18.9219C4.1767 18.9219 1.96875 16.7139 1.96875 14C1.96875 11.2861 4.1767 9.07812 6.89062 9.07812C8.65156 9.07812 10.2895 10.0284 11.1651 11.5581C11.1651 11.5581 11.1652 11.5583 11.1652 11.5584C11.5887 12.2971 11.8125 13.1415 11.8125 14C11.8125 14.8585 11.5887 15.7029 11.1651 16.4419ZM22.2031 26.9062C20.0923 26.9062 18.375 25.189 18.375 23.0781C18.375 22.4108 18.5492 21.7541 18.8786 21.1788C19.5599 19.9891 20.8338 19.25 22.2031 19.25C24.314 19.25 26.0312 20.9673 26.0312 23.0781C26.0312 25.189 24.314 26.9062 22.2031 26.9062Z"
    />
    <path
      d="M9.29715 11.0244C9.06248 10.8344 8.71817 10.8704 8.52802 11.1051C8.33787 11.3398 8.37402 11.6841 8.60869 11.8743C9.25455 12.3976 9.625 13.1724 9.625 14C9.625 15.5077 8.39836 16.7344 6.89062 16.7344C6.58864 16.7344 6.34375 16.9792 6.34375 17.2812C6.34375 17.5833 6.58864 17.8281 6.89062 17.8281C9.00145 17.8281 10.7188 16.1108 10.7188 14C10.7188 12.8409 10.2006 11.7564 9.29715 11.0244Z"
    />
  </svg>
</template>
